<template>
  <b-card no-body
    bg-variant="light-secondary"
    class="shadow-none"
  >
    <b-card-header>
      <b-card-title>{{$t('assigned_rutes')}}</b-card-title>
      <b-button
        v-b-toggle.sidebar-right 
        variant="flat-primary"
        class="btn-icon rounded-circle"
      >
        <svg width="18px" height="18px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="#87bced" d="M507.31 84.69L464 41.37c-6-6-14.14-9.37-22.63-9.37H288V16c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v16H56c-13.25 0-24 10.75-24 24v80c0 13.25 10.75 24 24 24h385.37c8.49 0 16.62-3.37 22.63-9.37l43.31-43.31c6.25-6.26 6.25-16.38 0-22.63zM224 496c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V384h-64v112zm232-272H288v-32h-64v32H70.63c-8.49 0-16.62 3.37-22.63 9.37L4.69 276.69c-6.25 6.25-6.25 16.38 0 22.63L48 342.63c6 6 14.14 9.37 22.63 9.37H456c13.25 0 24-10.75 24-24v-80c0-13.25-10.75-24-24-24z"/></svg>
      </b-button>
    </b-card-header>
    <b-card-body>
      <b-card-text>
        {{ $t('view_assigned_rutes') }}
      </b-card-text>
      <b-link v-b-toggle.sidebar-right class="card-link">
        {{$t('see_more')}}
      </b-link>
      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadow
      >
        <sidebar-assigned-routes/>
      </b-sidebar>
    </b-card-body>

  </b-card>
</template>

<script>
import SidebarAssignedRoutes from '@/views/learning-paths/components/SidebarAssignedRoutes.vue'

import {
  VBToggle,
  BLink,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BCardTitle,
  BSidebar,
} from 'bootstrap-vue'

export default {
  components:{
    BLink,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BCardTitle,
    BSidebar,

    SidebarAssignedRoutes
  },
  directives: {
    'b-toggle': VBToggle,
  },

}
</script>

<style>

</style>