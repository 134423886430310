var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-employee-task",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('Groups')))]),(_vm.dreamer.owner)?_c('b-button',{staticClass:"pointer p-0",attrs:{"variant":"link","to":{
      name: 'dreamer-edit',
      params: {
        id: _vm.dreamer.id,
        tab: 5
      }
    }}},[(_vm.userData.role != 'teacher')?_c('div',[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"PlusIcon","size":"18"}}),_vm._v(" "+_vm._s(_vm.$t('forms.add_model', { model: _vm.$t('models.group') }))+" ")],1):_vm._e()]):_vm._e()],1),(_vm.groups.length > 0)?_c('b-card-body',[_vm._l((_vm.groups),function(group){return [_c('div',{key:group.id,staticClass:"employee-task d-flex justify-content-between align-items-center"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(group.id == 0 ? _vm.$t('table.no_group') : group.name),expression:"group.id == 0 ? $t('table.no_group') : group.name",modifiers:{"hover":true,"bottom":true}}],attrs:{"rounded":"","variant":"light","size":"42","to":_vm.resolveGroupProperty(group) ? {
                name: 'group-view', params: {
                  id: group.id
                }
              } : null,"src":require('@/assets/images/avatar/groups/' + (group.id_avatar || 'null') + '.png?v4')}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[(_vm.resolveGroupProperty(group))?_c('b-link',{attrs:{"to":{
                name: 'group-view', params: {
                  id: group.id
                }
              }}},[_vm._v(" "+_vm._s(group.name)+" ")]):_c('span',[_vm._v(_vm._s(group.name))])],1)])],1),(_vm.resolveGroupProperty(group))?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:(_vm.$t('forms.unlink_group')),expression:"$t('forms.unlink_group')",modifiers:{"hover":true,"left":true}}],staticClass:"d-flex align-items-center pointer",on:{"click":function($event){return _vm.removeGroup(group.id)}}},[_c('small',{staticClass:"m-75",staticStyle:{"color":"tomato"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'unlink'],"size":"lg"}})],1)]):_vm._e()],1)]})],2):_c('b-card-body',[_vm._v(" "+_vm._s(_vm.$t('table.no_groups'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }