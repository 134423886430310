<template>
  <div>
    <b-row>
      <b-col cols="12" class="mt-2 mb-1">
        <div class="d-flex justify-content-between align-center">
          <h2 class="mb-0">{{$t('analytics.Analytics')}}</h2>
          <b-button :to="{ 
              name: 'graphics-details', 
              params: { 
                id: dreamer.id, 
              } 
          }" variant="primary">{{$t('analytics.more')}}</b-button>              
        </div>        
      </b-col>
    </b-row>
    
    <DreamerStatistics :analytics="analytics" :dreamer="dreamer" />

    <b-row>
      <b-col cols="12">
        <b-row class="match-height">
          <b-col md="6">
            <DatosGeneralesDeUso :dreamer="dreamer" />
          </b-col>
          <b-col md="6">
            <TiemposDeAprendizaje :dreamer="dreamer"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <UsoPorAreasAcademicas :dreamer="dreamer"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DreamerStatistics from '@/views/schools/dreamers/view/DreamerStatistics.vue'
import UsoPorAreasAcademicas from "./dreamer-charts/UsoPorAreasAcademicas.vue";
import DatosGeneralesDeUso from "./dreamer-charts/DatosGeneralesDeUso.vue";
import TiemposDeAprendizaje from "./dreamer-charts/TiemposDeAprendizaje.vue";

import {
  BButton,
  BCol,
  BRow,
} from 'bootstrap-vue'

export default {
  components:{
    BButton,
    BCol,
    BRow,
    
    DreamerStatistics,
    DatosGeneralesDeUso,
    UsoPorAreasAcademicas,
    TiemposDeAprendizaje,
  },
  props:{
    dreamer: {
      type: Object,
      required: true,
    },
    analytics: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>