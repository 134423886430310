<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{$t('analytics.general')}}</b-card-title>
      <b-dropdown
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
      <template #button-content>
        {{$t('analytics.'+period)}}
          <feather-icon
            icon="ChevronDownIcon"
            size="16"
          />
      </template>
        <b-dropdown-item
          v-for="day in periodOptions"
          :key="day"
          @click="period = day"
        >
          {{ $t('analytics.'+day) }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body >
      <b-tabs v-if="tabs.length >0">
        <b-tab :title="$t('analytics.'+tab)" v-for="tab, idx in tabs" :key="idx">
          <vue-apex-charts
            type="pie"
            height="300"
            class="my-1"
            :options="chartsDataByCategory[tab].chartOptions"
            :series="chartsDataByCategory[tab].series"
          />
          <!-- chart info -->
          <div
            v-for="(data,index) in chartsDataByCategory[tab].chartInfo"
            :key="data.name"
            class="d-flex justify-content-between"
            :class="index === chartsDataByCategory[tab].chartInfo.length - 1 ? 'mb-0':'mb-25'"
          >
            <div class="series-info d-flex align-items-center">
              <span class="mr-50 bullet bullet-sm" :style="{backgroundColor:data.iconColor}" />
              <span class="font-weight-bolder ml-75 mr-25 ">{{ $t('analytics.graphs.'+tab+'.'+data.name) }}</span>
            </div>
            <div>
              <span>{{ data.usage }}</span>
             
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <NoDataChartPlaceholderVue v-else />

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, 
  BCardBody, BTabs, BTab
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { getDreamerUsage } from '@/api/routes'
import NoDataChartPlaceholderVue from '@/views/sl-components/NoDataChartPlaceholder.vue'


export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BTabs, BTab,
    VueApexCharts,
    NoDataChartPlaceholderVue
  },
  props:{
    dreamer: {
      type: Object,
      required: true,
    }
  },
  data() {
    let hoy = new Date();
    let date = new Date()
    date.setFullYear(date.getFullYear() - 1);
    let start = date

    return {
      periodOptions:['week', 'month', 'year'],
      period: 'year',
      start_date: start,
      end_date: hoy,

      tabs:[],
      chartsDataByCategory: {},
    }
  },
  created() {
    this.getData()
  },
  watch:{
    period(){
      if(this.period == 'year'){
        let date = new Date()
        date.setFullYear(date.getFullYear() - 1);
        this.start_date = date
      }
      if(this.period == 'month'){
        let date = new Date()
        date.setMonth(date.getMonth() - 1);
        this.start_date = date
      }
      if(this.period == 'week'){
        let date = new Date()
        date.setDate(date.getDate() - 7);
        this.start_date = date
      }

      this.getData()

    }
  },
  methods:{
     async getData (){
      let data = {
        'dreamer_id': this.dreamer.id,
        'start_date': this.start_date.toISOString().substring(0,10),
        'end_date': this.end_date.toISOString().substring(0,10)
      } 
      await this.$http.post(getDreamerUsage, data).then(response => {
        //console.log("getDreamerUsage", response.data.data)
        this.tabs = Object.keys(response.data.data).filter(k => Object.keys(response.data.data[k]).length >0)

        let chartColors =[
          "#DD4124", // Red Orange
          "#45B8AC",  // Aqua
          "#FF6F61", // Coral
          "#6B5B95", // Royal Purple
          "#88B04B", // Moss Green
          "#F7CAC9", // Peach Pink
          "#92A8D1", // Sky Blue
          "#955251", // Marsala
          "#B565A7", // Orchid
          "#009B77", // Teal
        ];
        let my_chart_data = {}
        Object.keys(response.data.data).map(category => {
          let category_series = []
          let category_labels = []
          let category_info = []

          if(Object.keys(response.data.data[category]).length >0){
            Object.entries(response.data.data[category]).map((subcat, idx) => {
              if(subcat[1]>0){
                category_labels.push(this.$t( 'analytics.graphs.'+category+'.'+subcat[0]))
                category_series.push(subcat[1])
                category_info.push({
                  name: subcat[0],
                  iconColor: chartColors[idx],
                  usage: subcat[1],
                })
              }
            })
            my_chart_data[category] = {
              series: category_series, //SERIES
              chartOptions: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                labels: category_labels, //LABELS
                dataLabels: {
                  enabled: false,
                },
                legend: { show: false },
                stroke: { width: 0 },
                colors: chartColors,
              },
              chartInfo: category_info.reverse() //INFO
            }
          }
        })
        this.chartsDataByCategory = my_chart_data
      }).catch(error => {
        console.log("Err -> ", error)
      })
    },
  }
}
</script>
