var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between flex-column",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-avatar',{attrs:{"src":require('@/assets/images/avatar/AvatarSprite_'+(_vm.dreamer.avatar||'57')+'.png?v4'),"text":_vm.avatarText(_vm.dreamer.name),"variant":"light-primary","size":"104px","rounded":""}}),_c('div',{staticClass:"d-flex flex-column ml-1"},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-1 mr-1 break-word",staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.dreamer.name)+" ")]),(!_vm.dreamer.owner)?_c('b-badge',{staticStyle:{"display":"inline-block"},attrs:{"pill":"","variant":"warning"}},[_vm._v(_vm._s(_vm.$t('dreamer.shared')))]):_vm._e()],1),_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.$store.getters['user/getRole']=='teacher' || (_vm.$can('update', 'Dreamers') && _vm.canEdit))?[(_vm.dreamer.id !=0 && !_vm.dreamer.deleted_at)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('forms.edit')),expression:"$t('forms.edit')",modifiers:{"hover":true,"top":true}}],staticClass:"mr-1 mb-1",attrs:{"type":"button","to":{ 
                  name: 'dreamer-edit', 
                  params: { 
                    id: _vm.dreamer.id, 
                  } 
                },"variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"d-none d-md-inline ml-1"},[_vm._v(_vm._s(_vm.$t('forms.edit')))])],1):_vm._e()]:_vm._e(),(_vm.dreamer.owner && _vm.$can('delete', 'Dreamers'))?[(_vm.dreamer.id !=0 && !_vm.dreamer.deleted_at)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('forms.delete')),expression:"$t('forms.delete')",modifiers:{"hover":true,"top":true}}],staticClass:"mr-1 mb-1",attrs:{"variant":"outline-danger","type":"button"},on:{"click":_vm.deleteModel}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"d-none d-md-inline ml-1"},[_vm._v(_vm._s(_vm.$t('forms.delete')))])],1):_vm._e()]:_vm._e(),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('forms.go_back')),expression:"$t('forms.go_back')",modifiers:{"hover":true,"top":true}}],staticClass:"mr-1 mb-1",attrs:{"variant":"outline-secondary","type":"button"},on:{"click":function($event){_vm.hasHistory() 
                ? _vm.$router.go(-1) 
                : _vm.$router.push({ name: 'dreamers' })}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_c('span',{staticClass:"d-none d-md-inline ml-1"},[_vm._v(_vm._s(_vm.$t('forms.go_back')))])],1)],2)])],1)]),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('table',{staticClass:"mt-2 w-100"},[_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"SmileIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('Groups')))])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.groups.length)+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"GiftIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('fields.f_nac')))])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(new Date(_vm.dreamer.birthdate).toLocaleDateString())+" ("+_vm._s(_vm.dreamer.age)+" "+_vm._s(_vm.$t('fields.years_old'))+") ")])]),(_vm.dreamer.created_at)?_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"CalendarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('fields.created_at')))])],1),_c('td',[_vm._v(" "+_vm._s(new Date(_vm.dreamer.created_at).toLocaleDateString())+" ")])]):_vm._e(),(_vm.passwordArray && _vm.passwordArray.length)?_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"UnlockIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('pin')))])],1),_c('td',{domProps:{"innerHTML":_vm._s(_vm.imageTooltip(_vm.passwordArray))}})]):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }