<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        sm="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="require('@/assets/images/avatar/AvatarSprite_'+(dreamer.avatar||'57')+'.png?v4')"

            :text="avatarText(dreamer.name)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-1 mr-1 break-word" style="display: inline-block;">
                {{ dreamer.name }}
              </h4>
              <b-badge style="display:inline-block;" v-if="!dreamer.owner" pill variant="warning">{{$t('dreamer.shared')}}</b-badge>
            </div>
            <div class="d-flex flex-wrap">
              <template v-if="$store.getters['user/getRole']=='teacher' || ($can('update', 'Dreamers') && canEdit)">
                <b-button
                  type="button"
                  v-b-tooltip.hover.top="$t('forms.edit')"
                  class="mr-1 mb-1"
                  :to="{ 
                    name: 'dreamer-edit', 
                    params: { 
                      id: dreamer.id, 
                    } 
                  }"
                  variant="primary"
                  v-if="dreamer.id !=0 && !dreamer.deleted_at"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                  <span class="d-none d-md-inline ml-1">{{ $t('forms.edit')}}</span>
                  
                </b-button>
              </template>
              <template v-if="dreamer.owner && $can('delete', 'Dreamers')">
                <b-button
                  variant="outline-danger"
                  type="button"
                  v-b-tooltip.hover.top="$t('forms.delete')"
                  class="mr-1 mb-1"
                  v-if="dreamer.id !=0 && !dreamer.deleted_at"
                  @click="deleteModel"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span class="d-none d-md-inline ml-1">{{ $t('forms.delete')}}</span>
                </b-button>
              </template>
              
              <b-button
                variant="outline-secondary"
                type="button"
                v-b-tooltip.hover.top="$t('forms.go_back')"
                class="mr-1 mb-1"
                @click="hasHistory() 
                  ? $router.go(-1) 
                  : $router.push({ name: 'dreamers' })"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                  />
                  <span class="d-none d-md-inline ml-1">{{ $t('forms.go_back') }}</span>

              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        sm="6"

      >
        <table class="mt-2 w-100">
          
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="SmileIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('Groups') }}</span>
            </th>
            <td class="pb-50">
              {{ groups.length  }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="GiftIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('fields.f_nac') }}</span>
            </th>
            <td class="pb-50">
              {{ new Date(dreamer.birthdate).toLocaleDateString() }} ({{dreamer.age}} {{$t('fields.years_old')}})
            </td>
          </tr>
        
         <tr v-if="dreamer.created_at">
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('fields.created_at')}}</span>
            </th>
            <td>
              {{ new Date(dreamer.created_at).toLocaleDateString() }}
            </td>
          </tr>
          <tr v-if="passwordArray && passwordArray.length">
            <th class="pb-50">
              <feather-icon
                icon="UnlockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('pin')}}</span>
            </th>
            <td v-html="imageTooltip(passwordArray)">
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
  
</template>


<script>
import { deleteDreamer  } from '@/api/routes'
import {
  BCard, BButton, BAvatar, BRow, BCol,VBTooltip,BLink, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,BLink, BBadge, 
  },
  props: {
    dreamer: {
      type: Object,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    canEdit:{
      type: Boolean,
      required: false,
      default: true
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed: {
    passwordArray() {
      return JSON.parse(this.dreamer.password);
    },
  },
  methods: {
      imageTooltip(pinArray) {
      return pinArray.map(pin => `<img src="${require('@/assets/images/passwords/' + pin + '.png')}" style="width: 35px; height: 35px;" />`).join('');
    },
    hasHistory () { 
      return window.history.length > 2 
    },
    deleteModel(){
      this.$bvModal
        .msgBoxConfirm(this.$t('table.delete_dreamer_confirmation'), {
          title: this.$t('forms.delete'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){

            this.$http.delete(deleteDreamer+'/'+this.dreamer.id).then( response =>{
              // console.log(response)
              if(response.data.status == 200){
                //this.$emit('refetch-data')
                this.$router.push({ name: 'dreamers' });
                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
              this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            })
          }
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>
