<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{$t('analytics.usage_time')}}</b-card-title>
      <b-dropdown
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
      <template #button-content>
        {{$t('analytics.'+period)}}
          <feather-icon
            icon="ChevronDownIcon"
            size="16"
          />
      </template>
        <b-dropdown-item
          v-for="day in periodOptions"
          :key="day"
          @click="period = day"
        >
          {{ $t('analytics.'+day) }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body >
      <b-tabs>
        <b-tab :title="$t('analytics.by_day_and_hours')" >
          <UsoPorDiasHoras :dreamer="dreamer" 
            :start_date="start_date"
            :end_date="end_date"
          />
        </b-tab>
        <b-tab :title="$t('analytics.hours')" >
          <template v-if="!loadingByHours">
            <vue-apex-charts v-if="chartsDataByHour.series.length > 0"
              type="pie"
              height="300"
              class="my-1"
              :options="chartsDataByHour.chartOptions"
              :series="chartsDataByHour.series"
            />
            <NoDataChartPlaceholderVue v-else />
            <div
              v-for="(data,index) in chartsDataByHour.chartInfo"
              :key="data.name"
              class="d-flex justify-content-between"
              :class="index === chartsDataByHour.chartInfo.length - 1 ? 'mb-0':'mb-25'"
            >
              <div class="series-info d-flex align-items-center">
                <span class="mr-50 bullet bullet-sm" :style="{backgroundColor:data.iconColor}" />
                <span class="font-weight-bolder ml-75 mr-25 ">{{ data.name }} hs</span>
              </div>
              <div>
                <span>{{ data.usage }}%</span>
              
              </div>
            </div>
          </template>
        </b-tab>
        <b-tab :title="$t('analytics.weekly')" >
          <template v-if="!loadingByDays">
            <vue-apex-charts v-if="chartsDataByDays.series.length >0"
              type="pie"
              height="300"
              class="my-1"
              :options="chartsDataByDays.chartOptions"
              :series="chartsDataByDays.series"
            />
            <NoDataChartPlaceholderVue v-else />
            <div
              v-for="(data,index) in chartsDataByDays.chartInfo"
              :key="data.name"
              class="d-flex justify-content-between"
              :class="index === chartsDataByDays.chartInfo.length - 1 ? 'mb-0':'mb-25'"
            >
              <div class="series-info d-flex align-items-center">
                <span class="mr-50 bullet bullet-sm" :style="{backgroundColor:data.iconColor}" />
                <span class="font-weight-bolder ml-75 mr-25 ">{{ data.name }}</span>
              </div>
              <div>
                <span>{{ data.usage }}%</span>
              
              </div>
            </div>
          </template>
        </b-tab>
        
      </b-tabs> 
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, 
  BCardBody, BTabs, BTab
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { getDreamerDayTimeData, getUsagePerWeekday } from '@/api/routes'
import NoDataChartPlaceholderVue from '@/views/sl-components/NoDataChartPlaceholder.vue'
import UsoPorDiasHoras from '@/views/dreamers/components/dreamer-charts/UsoPorDiasHoras.vue'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BTabs, BTab,
    VueApexCharts,
    NoDataChartPlaceholderVue,
    UsoPorDiasHoras
  },
  props:{
    dreamer: {
      type: Object,
      required: true,
    }
  },
  data() {
    let hoy = new Date();
    let date = new Date()
    date.setFullYear(date.getFullYear() - 1);
    let start = date

    return {
      periodOptions:['week', 'month', 'year'],
      period: 'year',
      start_date: start,
      end_date: hoy,

      chartsDataByHour: {},
      chartsDataByDays: {},

      loadingByHours: true,
      loadingByDays: true
    }
  },
  created() {
    this.getDataByHours()
    this.getDataByDays()
  },
  watch:{
    period(){
      if(this.period == 'year'){
        let date = new Date()
        date.setFullYear(date.getFullYear() - 1);
        this.start_date = date
      }
      if(this.period == 'month'){
        let date = new Date()
        date.setMonth(date.getMonth() - 1);
        this.start_date = date
      }
      if(this.period == 'week'){
        let date = new Date()
        date.setDate(date.getDate() - 7);
        this.start_date = date
      }

      this.getDataByHours()
      this.getDataByDays()

    }
  },
  methods:{
    async getDataByHours (){
      this.loadingByHours = true
      let data = {
        'dreamer_id': this.dreamer.id,
        'start_date': this.start_date.toISOString().substring(0,10),
        'end_date': this.end_date.toISOString().substring(0,10)
      } 
      //console.log("data", data)
      await this.$http.post(getDreamerDayTimeData, data).then(response => {
        // console.log("getDreamerDayTimeData", response.data.data)
        let chartColors =[
          "#DD4124", // Red Orange
          "#45B8AC",  // Aqua
          "#FF6F61", // Coral
          "#6B5B95", // Royal Purple
          "#88B04B", // Moss Green
          "#F7CAC9", // Peach Pink
          "#92A8D1", // Sky Blue
          "#955251", // Marsala
          "#B565A7", // Orchid
          "#009B77", // Teal
        ];
        let hr_range = ['0-8', '8-12', '12-17', '17-21', '21-24']

        let category_series = []
        let category_labels = []
        let category_info = []
        hr_range.map( (hr, idx) => {
          let t = Math.round(response.data.data[hr] * 100) / 100
          category_labels.push(hr)
          category_series.push(t)
          category_info.push({
            name: hr,
            iconColor: chartColors[idx],
            usage: t,
          })
        })

        let my_chart_data = {
              series: category_series, //SERIES
              chartOptions: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                labels: category_labels, //LABELS
                dataLabels: {
                  enabled: false,
                },
                legend: { show: false },
                stroke: { width: 0 },
                colors: chartColors,
              },
              chartInfo: category_info //INFO
            }
        this.chartsDataByHour = my_chart_data
        this.loadingByHours = false

      }).catch(error => {
        console.log("Err -> ", error)
      })
    },
    async getDataByDays (){
      this.loadingByDays = true
      let data = {
        'dreamer_id': this.dreamer.id,
        'start_date': this.start_date.toISOString().substring(0,10),
        'end_date': this.end_date.toISOString().substring(0,10)
      } 
      //console.log("data", data)
      await this.$http.post(getUsagePerWeekday, data).then(response => {
        //console.log("getUsagePerWeekday", response.data.data)
        let chartColors =[
          "#DD4124", // Red Orange
          "#45B8AC",  // Aqua
          "#FF6F61", // Coral
          "#6B5B95", // Royal Purple
          "#88B04B", // Moss Green
          "#F7CAC9", // Peach Pink
          "#92A8D1", // Sky Blue
          "#955251", // Marsala
          "#B565A7", // Orchid
          "#009B77", // Teal
        ];
        let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

        let category_series = []
        let category_labels = []
        let category_info = []
        days.map((dia, idx)  => {
          let t = Math.round(response.data.data[dia] * 100) / 100
            category_labels.push(this.$t(dia))
            category_series.push(t)
            category_info.push({
              name: this.$t(dia),
              iconColor: chartColors[idx],
              usage: t,
            })
        })
       
        let my_chart_data = {
              series: category_series, //SERIES
              chartOptions: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                labels: category_labels, //LABELS
                dataLabels: {
                  enabled: false,
                },
                legend: { show: false },
                stroke: { width: 0 },
                colors: chartColors,
              },
              chartInfo: category_info //INFO
            }
        this.chartsDataByDays = my_chart_data
        this.loadingByDays = false

      }).catch(error => {
        console.log("Err -> ", error)
      })
    },
  }
}
</script>
