<template>
  <b-card no-body class="card-employee-task">
    <b-card-header>
      <b-card-title>{{ $t('Groups') }}</b-card-title>
      <b-button v-if="dreamer.owner" variant="link" class="pointer p-0" :to="{
        name: 'dreamer-edit',
        params: {
          id: dreamer.id,
          tab: 5
        }
      }">
        <div v-if="userData.role != 'teacher'">
          <feather-icon icon="PlusIcon" size="18" class="cursor-pointer" />
          {{ $t('forms.add_model', { model: $t('models.group') }) }}
        </div>
      </b-button>
    </b-card-header>

    <!-- body -->
    <b-card-body v-if="groups.length > 0">
      <template v-for="group in groups">
        <div :key="group.id" class="employee-task d-flex justify-content-between align-items-center">
          <b-media no-body>
            <b-media-aside class="mr-75">
              <b-avatar rounded variant="light"
                v-b-tooltip.hover.bottom="group.id == 0 ? $t('table.no_group') : group.name" size="42" :to="resolveGroupProperty(group) ? {
                  name: 'group-view', params: {
                    id: group.id
                  }
                } : null"
                :src="require('@/assets/images/avatar/groups/' + (group.id_avatar || 'null') + '.png?v4')" />
            </b-media-aside>
            <b-media-body class="my-auto">
              <h6 class="mb-0">
                <b-link v-if="resolveGroupProperty(group)" :to="{
                  name: 'group-view', params: {
                    id: group.id
                  }
                }">
                  {{ group.name }}
                </b-link>
                <span v-else>{{ group.name }}</span>
              </h6>
            </b-media-body>
          </b-media>
          <div v-if="resolveGroupProperty(group)" class="d-flex align-items-center pointer"
            @click="removeGroup(group.id)" v-b-tooltip.hover.left="$t('forms.unlink_group')">
            <small class="m-75" style="color:tomato;">
              <font-awesome-icon :icon="['fas', 'unlink']" size="lg" />
            </small>
          </div>
        </div>
      </template>
    </b-card-body>
    <b-card-body v-else>
        {{$t('table.no_groups')}}
    </b-card-body>
  </b-card>
</template>

<script>
import { schoolDreamerUnlink } from '@/api/routes'
import { 
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BLink,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  VBTooltip
} from "bootstrap-vue";

export default {
  components:{
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BLink,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props:{
    dreamer: {
      type: Object,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
  },
  data(){
    return {
      userData: this.$store.getters['user/getUserData'],
    }
  },
  methods: {
    resolveGroupProperty(group) {
      return this.userData.id == group.id_user
    },
    removeGroup(group_id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Are you sure you want to unlink this group?'), {
          title: this.$t('forms.unlink_group'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $t('Yes'),
          cancelTitle: $('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'dreamer_id': this.dreamer.id,
              'tab': 'group',
              'group_id': group_id
            }
            this.$http.post(schoolDreamerUnlink, data).then(response => {
              // console.log(response)
              if (response.data.status == 200) {
                this.makeToast('success', this.$t('Success'), this.$t('message.OK_DREAMER_1003'));
                this.$emit('getData')
              } else {
                this.makeToast('danger', this.$t('Error'), response.data.msg);
              }
            }).catch(error => {
              // console.log("Err -> ", error);
              this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
            })
          }
        })
    },
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>