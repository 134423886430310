<template>
  <div v-if="!loading">
    <!-- BREADCRUMBS -->
    <custom-breadcrumb :pageTitle="dreamer.name" :breadcrumb="breadcrumb"></custom-breadcrumb>

    <!-- DREAMER INFO CARD -->
    <b-row>
      <b-col cols="12">
        <info-card :dreamer="dreamer" :groups="groups" />
      </b-col>
    </b-row>

    <!-- DREAMER GROUPS & LP INFO -->
    <b-row class="match-height">
      <!-- GRUPOS -->
      <b-col cols="12" md="6">
        <DreamerGroupsInfoCard 
          :dreamer="dreamer"
          :groups="groups"
          @getData="getData"
        />
      </b-col>
      <!-- ASSIGNED LPs -->
      <b-col cols="12" md="6">
        <DreamerAssignedLearningPathsSidebar />
     </b-col>
    </b-row>
      
    <!-- ANALITICAS -->
    <DreamerAnalytics :dreamer="dreamer" :analytics="analytics"/>
   
  </div>
  <div v-else class="d-flex justify-content-center mb-1">
    <b-spinner label="Loading..." />
  </div>
</template>

<script>
import { getDreamer } from '@/api/routes'

import {BCol,  BRow, BSpinner,} from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import InfoCard from './components/InfoCard.vue'
import DreamerGroupsInfoCard from './components/DreamerGroupsInfoCard.vue'
import DreamerAssignedLearningPathsSidebar from './components/DreamerAssignedLearningPathsSidebar.vue'
import DreamerAnalytics from './components/DreamerAnalytics.vue'

export default {
  components: {
    BCol, BRow, BSpinner,

    // Local Components
    CustomBreadcrumb,
    InfoCard,
    DreamerGroupsInfoCard,
    DreamerAssignedLearningPathsSidebar,
    DreamerAnalytics,

  },
  data() {
    return {
      loading: true,
      dreamer: {},
      groups: [],
      analytics: {},
      dreamerChartData: [],
      dreamerChartLabels: [],

    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    breadcrumb() {
      return [
        {
          text: this.$t('Dreamers'),
          to: 'dreamers'
        },
        {
          text: this.dreamer.name,
          active: true,
        },
      ]
    },
  },
  watch: {
    '$i18n.locale': {
      handler: function () {
        this.getData()
      },
    }
  },
  methods: {
    async getData() {
      this.loading =true
      await this.$http.get(getDreamer + '/' + this.$route.params.id).then(response => {
        if (response.data.status == 200) {
          this.dreamer = response.data.dreamer;
          this.analytics = response.data.analytics;
          this.groups = response.data.dreamer.groups;
          this.loading =false
        } else {
          this.makeToast('danger', this.$t('Error'), this.$t('message.' + response.data.msg_code));
        }
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      })
    },
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}

</script>

<style scoped>
</style>
