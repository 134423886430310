<template>
  <div v-if="!loading">
    <vue-apex-charts v-if="series.length >0"
        type="heatmap"
        height="500"
        :options="chartOptions"
        :series="series"
    />
    <NoDataChartPlaceholderVue v-else />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { getDayAndHourUsage } from '@/api/routes'
import NoDataChartPlaceholderVue from '@/views/sl-components/NoDataChartPlaceholder.vue'

export default {
  components:{
    VueApexCharts,
    NoDataChartPlaceholderVue
  },
  props: {
    dreamer: {
      type: Object,
      required: true,
    },
    start_date: {
      type: Date,
      required: true,
    },
    end_date: {
      type: Date,
      required: true,
    }
  },
  data(){
    return {
      loading: true,
      series: [],
      chartOptions: {},
    }
  },
  created() {
    this.getDayAndHourUsage ()
  },
  watch:{
    start_date(){
      this.getDayAndHourUsage ()
    }
  },
  methods: {
    async getDayAndHourUsage() {
        this.loading = true;

        let data = {
            'dreamer_id': this.dreamer.id,
            'start_date': this.start_date.toISOString().substring(0, 10),
            'end_date': this.end_date.toISOString().substring(0, 10)
        };

        await this.$http.post(getDayAndHourUsage, data).then( response => {

            if (response.data.status == 200) {
                //console.log('Respuesta de getDayAndHourUsage', response.data);

                if(response.data.data.length > 0){
                  let seriesData = []
                  let horas = Array(24).fill('').map((_,i) => i+'hs').reverse()
                  let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                  
                  seriesData = horas.map( h => ({
                    name: h,
                    data: Array(7).fill({ x: "", y: 0 }).map( (_,i) => ({ x: this.$t(days[i]).substring(0, 3).toUpperCase(), y: 0 }))
                    //[{x: '', y: ''}] x=dia, y = cantidad de partidas
                  }))

                  let all_values = []
                  response.data.data.map(item => {
                    all_values.push(item.value)
                    days.map((d, idx) => {
                      if(d == item.weekday){
                        let short_d = this.$t(d).substring(0, 3).toUpperCase()
                        seriesData[item.hour].data[idx] = { x: short_d, y: item.value };
                      }
                    })
                  })
                  this.series = seriesData;
                  
                  let colorRanges = [
                      { from: 0, to: 0, name: this.$t('analytics.no_activity'), color: '#E5ECF3' },
                  ]
                  
                  let max_val = Math.max.apply(Math, all_values);
                  let step = Math.ceil(max_val / 5)
                  let counter = 0
                  let colors = ['#bbeeff', '#99ccff', '#77aaff', '#5588ff', '#3366ff' ]
                  for (let index = 1; index <= 5; index++) {
                    colorRanges.push({ from: counter+1, to: counter+step, name: `${counter+1} - ${counter+step} ${this.$t('analytics.played_contents')}`, color: colors[index-1] })
                    counter += step
                  }

                  this.chartOptions = {
                    dataLabels: {
                      enabled: false,
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                    },
                    tooltip: {
                      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        return (
                          '<div class="px-1 py-50">' +
                          '<span>' + this.$t('analytics.played_contents')+
                          /* w.config.series[seriesIndex].data[dataPointIndex].x + */
                          ': ' +
                          w.config.series[seriesIndex].data[dataPointIndex].y +
                          /* ' ' + this.$t('analytics.played_contents') + */
                          '</span>' +
                          '</div>'
                        );
                      },
                    },
                    colors: ['#67C3F0'],
                    chart: {
                      toolbar: {
                        show: false,
                      },
                    },
                    stroke: {
                      width: 1
                    },
                    plotOptions: {
                      heatmap: {
                        radius: 0,
                        colorScale: {
                            ranges: colorRanges,
                        },
                      }
                    },
                  }
                } else {
                  this.series = []
                }
                this.loading = false;
            } else {
              console.log("Error -> ", response);
              this.loading = false;
            }
        }).catch (error => {
          console.log("Error en getDayAndHourUsage -> ", error);
          this.loading = false;
        })
    }
}
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';

.apexcharts-canvas .apexcharts-legend{
  padding-top: 20px;
  display: block;
}
.apexcharts-canvas .apexcharts-legend .apexcharts-legend-series{
  margin: 5px !important;
}


</style>