<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{$t('analytics.top10academicAreas')}}</b-card-title>
      <b-dropdown
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
      <template #button-content>
        {{$t('analytics.'+period)}}
          <feather-icon
            icon="ChevronDownIcon"
            size="16"
          />
      </template>
        <b-dropdown-item
          v-for="day in periodOptions"
          :key="day"
          @click="period = day"
        >
          {{ $t('analytics.'+day) }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body >
      <b-tabs v-if="tabs.length >0">
        <b-tab :title="$t('analytics.graphs.academicAreas.'+tab)" v-for="tab, idx in tabs" :key="idx">
          <vue-apex-charts
            type="bar"
            height="400"
            class="my-1"
            :options="chartsDataByCategory[tab].chartOptions"
            :series="chartsDataByCategory[tab].series"
          />
        </b-tab>
      </b-tabs>
      <NoDataChartPlaceholderVue v-else />

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, 
  BCardBody, BTabs, BTab
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { getDreamerContentUsagePerArea, getDreamerLastTenContentUsage } from '@/api/routes'
import NoDataChartPlaceholderVue from '@/views/sl-components/NoDataChartPlaceholder.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BTabs, BTab,
    VueApexCharts,
    NoDataChartPlaceholderVue
  },
  props:{
    dreamer: {
      type: Object,
      required: true,
    }
  },
  data() {
    let hoy = new Date();
    let date = new Date()
    date.setFullYear(date.getFullYear() - 1);
    let start = date

    return {
      periodOptions:['week', 'month', 'year'],
      period: 'year',
      start_date: start,
      end_date: hoy,

      tabs:[],
      chartsDataByCategory: {},
    }
  },
  created() {
    this.getData()
    this.getFbkGeneralData()
  },
  watch:{
    period(){
      if(this.period == 'year'){
        let date = new Date()
        date.setFullYear(date.getFullYear() - 1);
        this.start_date = date
      }
      if(this.period == 'month'){
        let date = new Date()
        date.setMonth(date.getMonth() - 1);
        this.start_date = date
      }
      if(this.period == 'week'){
        let date = new Date()
        date.setDate(date.getDate() - 7);
        this.start_date = date
      }

      this.getData()

    }
  },
  methods:{
    async getData (){
      let data = {
        'dreamer_id': this.dreamer.id,
        'start_date': this.start_date.toISOString().substring(0,10),
        'end_date': this.end_date.toISOString().substring(0,10)
      } 
      await this.$http.post(getDreamerContentUsagePerArea, data).then(response => {
        //console.log("getDreamerContentUsagePerArea", response.data.data)
        this.tabs = Object.keys(response.data.data).filter(k => Object.keys(response.data.data[k]).length >0)

        let chartColors = [$themeColors.primary, $themeColors.warning, $themeColors.danger, '#4793AF', '#FFC470', '#DD5746', '#1A4D2E', '#C65BCF', '#FF76CE', '#03AED2', '#F97300']
        let my_chart_data = {}
        Object.keys(response.data.data).map(category => {
          let category_series = []
          let category_labels = []
          let category_info = []

          if(Object.keys(response.data.data[category]).length >0){
            let sorted = Object.values(response.data.data[category]);
            sorted.sort((a,b) => b.usage - a.usage);
            sorted.map((contenido, idx) => {
              let u = Math.round(contenido.usage * 100) / 100

              category_labels.push(contenido.name[0].value.length>25?contenido.name[0].value.slice(0,25)+'...':contenido.name[0].value)
              category_series.push(u)
              category_info.push({
                name: contenido.name[0].value,
                iconColor: chartColors[idx],
                usage: u
              })
            })
            my_chart_data[category] = {
              series: [{data:category_series}], //SERIES
              chartOptions: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                colors: chartColors,
                plotOptions: {
                  bar: {
                    horizontal: false,
                    barHeight: '50%',
                    endingShape: 'rounded',
                  },
                },
                grid: {
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                },
                xaxis: {
                  categories: category_labels,
                },
              },
            }
          }
        })
        this.chartsDataByCategory = my_chart_data
      }).catch(error => {
        console.log("Err -> ", error)
      })
    },
    async getFbkGeneralData(){
      let data = {
        'dreamer_id': this.dreamer.id,
        'start_date': this.start_date.toISOString().substring(0,10),
        'end_date': this.end_date.toISOString().substring(0,10)
      } 
      await this.$http.post(getDreamerLastTenContentUsage, data).then(response => {
        //console.log("getDreamerLastTenContentUsage", response.data.data)

        let chartColors = [$themeColors.primary, $themeColors.warning, $themeColors.danger, '#4793AF', '#FFC470', '#DD5746', '#1A4D2E', '#C65BCF', '#FF76CE', '#03AED2', '#F97300']
        let my_chart_data = {}
        let category_series = []
        let category_labels = []
        let category_info = []

        if(Object.keys(response.data.data).length >0){
          this.tabs.unshift('general')

          let sorted = Object.values(response.data.data);
          sorted.sort((a,b) => b.usage - a.usage);
          sorted.map((contenido, idx) => {
            let u = Math.round(contenido.usage * 100) / 100
            category_labels.push(contenido.name[0].value.length>25?contenido.name[0].value.slice(0,25)+'...':contenido.name[0].value)
            category_series.push(u)
            category_info.push({
              name: contenido.name[0].value,
              iconColor: chartColors[idx],
              usage: u,
              img: contenido.browerImg,
              type: contenido.type
            })
          })
          my_chart_data = {
            series: [{data:category_series}], //SERIES
            chartOptions: {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              colors: chartColors,
              plotOptions: {
                bar: {
                  horizontal: false,
                  barHeight: '50%',
                  endingShape: 'rounded',
                },
              },
              grid: {
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              dataLabels: {
                enabled: true,
              },
              xaxis: {
                categories: category_labels,
              },
            },
          }
        }
        this.chartsDataByCategory['general'] = my_chart_data
      }).catch(error => {
        console.log("Err -> ", error)
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>