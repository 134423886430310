<template>
    <!-- DREAMER DATA COMPONETS -->
    <!-- Stats Card Horizontal -->

    <b-row class="match-height">
        <b-col cols="12" sm="6" md="3">
            <statistic-card-horizontal icon="PenToolIcon" :statistic="resolveTotalTime(analytics.total_time_mins)"
            :statistic-title="$t('dash.statistics.total_learning')" />
            </b-col>
            <b-col cols="12" sm="6" md="3">
                <statistic-card-horizontal icon="ClockIcon" color="danger"
                :statistic="resolveAvgTime(analytics.avg_time_secs)"
                :statistic-title="$t('dash.statistics.session_time')" />
        </b-col>
        <b-col cols="12" sm="6" md="3">
            <statistic-card-horizontal icon="HexagonIcon" color="success" :statistic="dreamer.gems"
                :statistic-title="$t('dash.statistics.gems')" />
        </b-col>
        <b-col cols="12" sm="6" md="3">
            <statistic-card-horizontal icon="SmileIcon" color="warning" :statistic="dreamer.coins"
            statistic-title="Smilies" />
        </b-col>
    </b-row>

</template>
  
<script>

import {
    BCol,
    BRow,
} from 'bootstrap-vue'

import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
    components: {
        BCol,
        BRow,
        StatisticCardHorizontal
    },
    props: {
        dreamer: {
            type: Object,
            default: () => {}
        },
        analytics: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {

        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
        resolveAvgTime(avg_time_secs) {
            let secs = avg_time_secs
            let mins = Math.floor(secs / 60);
            let left_secs = secs - (mins * 60)
            let hours = Math.floor(mins / 60);
            let left_mins = mins - (hours * 60)

            let tot_time = ''

            if (hours > 0) {
                if (hours < 10) {
                    tot_time = tot_time + '0'
                }
                tot_time = tot_time + hours + ':'
            } else {
                tot_time = tot_time + '00:'
            }

            if (left_mins > 0) {
                if (left_mins < 10) {
                    tot_time = tot_time + '0'
                }
                tot_time = tot_time + left_mins + ':'
            } else {
                tot_time = tot_time + '00:'
            }

            if (left_secs > 0) {
                if (left_secs < 10) {
                    tot_time = tot_time + '0'
                }
                tot_time = tot_time + left_secs
            } else {
                tot_time = tot_time + '00'
            }

            if (tot_time == '') {
                tot_time = '00:00:00'
            }
            return tot_time
        },
        resolveTotalTime(total_time_mins) {
            let mins = total_time_mins
            let hours = Math.floor(mins / 60);
            let left_mins = mins - (hours * 60)
            let tot_time = ''
            if (hours > 0) {
                tot_time = tot_time + hours + 'h '
            }
            if (left_mins > 0) {
                tot_time = tot_time + left_mins + 'm'
            }
            if (tot_time == '') {
                tot_time = '0m'
            }

            return tot_time
        },
    }
}
</script>
  
<style></style>
  